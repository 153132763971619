import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

let init = {
  lng: localStorage.getItem('lang'),
  detection: {
    order: ['localStorage', 'lang'],
    lookupQuerystring: 'lang',
    lookupLocalStorage: 'lang',
    caches: ['localStorage']
  },
  fallbackLng: 'EN',
  interpolation: { escapeValue: false }
};

if (process.env.NODE_ENV === 'development') {
  const resources = {
    en: { translation: translationEN },
    de: { translation: translationDE }
  };

  init = { ...init, resources };
} else {
  init = {
    ...init,
    backend: {
      loadPath: `${process.env.REACT_APP_GATEWAY_URL}/translations?language={{lng}}`
    }
  };
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(init);

export default i18n;
