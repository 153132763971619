import { lazy } from 'react';

import { PERMISSIONS } from 'constants/security';

const Forbidden = lazy(() => import('pages/errors/forbidden'));
const Translations = lazy(() => import('pages/translations'));

export const routes = [{
  path: '/forbidden',
  exact: true,
  component: Forbidden,
  isPublic: true
}, {
  path: '/translations',
  exact: true,
  component: Translations,
  permissions: [PERMISSIONS.VIEW_TRANSLATION_LIST]
}];
