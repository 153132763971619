export const getStorage = key => {
  const value = localStorage.getItem(key);

  try {
    const obj = JSON.parse(value);

    if (obj && typeof obj === 'object' && obj !== null) {
      return obj;
    }
  } catch (err) {
    return value;
  }

  return value;
};
