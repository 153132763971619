import React, { Suspense, useState } from 'react';
import { Switch } from 'react-router-dom';
import useSWR from 'swr';
import { useSelector, useDispatch } from 'react-redux';

import { LoadingSpinner, Toast } from 'spacer-ui-kit';
import { TopBar } from 'spacer-ui-components';

import { setUser } from 'store/security';
import { routes } from 'routes';

import axios from 'utils/axios';
import { getStorage } from 'utils/use-storage';
import { getApps, languages } from 'utils/navigation';

import Route from 'components/security/route';
import SideNavbar from 'components/layout/side-nav';

const App = () => {
  const user = useSelector(state => state.security.user);
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(null);

  const { data: me, error } = useSWR(`${process.env.REACT_APP_GATEWAY_URL}/auth/me`, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 60000 // How often the authentication should be check (to be determined)
  });

  const { data: notifications, mutate: mutateNotifications } = useSWR(
    me && process.env.REACT_APP_HAS_NOTIFICATION ? `${process.env.REACT_APP_GATEWAY_URL}/notifications/users/${me.id}` : null,
    { refreshInterval: 5000 }
  );

  if (!me && !error) {
    return <LoadingSpinner header="Please wait" />;
  }

  if (me) {
    dispatch(setUser(me));
  }

  const logout = async () => {
    await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/logout`);

    dispatch(setUser(null));

    window.location.href = process.env.REACT_APP_SSO_LOGIN_URL;
  };

  const markAllRead = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_GATEWAY_URL}/notifications/users/${me.id}`);
      mutateNotifications();

    } catch (error) {
      setServerError('Could not mark notifications as read');
    }
  };

  const markAsRead = async id => {
    try {
      await axios.put(`${process.env.REACT_APP_GATEWAY_URL}/notifications/${id}/read`);
      mutateNotifications();
    } catch (error) {
      setServerError('Could not mark notification as read');
    }
  };

  const archive = async id => {
    try {
      await axios.put(`${process.env.REACT_APP_GATEWAY_URL}/notifications/${id}/archive`);
      mutateNotifications();
    } catch (error) {
      setServerError('Could not archive notification');
    }
  };

  return (
    <>
      <TopBar
        user={user}
        hasNotification={(process.env.REACT_APP_HAS_NOTIFICATION === 'true')}
        selectedLanguage={getStorage('lang')}
        onLogout={() => logout()}
        languages={languages}
        appsList={getApps(user)}
        notifications={notifications}
        onMarkAllNotificationsAsRead={markAllRead}
        onMarkNotificationAsRead={markAsRead}
        onArchiveNotification={archive}
      />
      <div className='min-h-screen bg-light flex flex-row'>
        <Suspense fallback={<LoadingSpinner header="Please wait" />}>
          <SideNavbar />
        </Suspense>
        <div className='w-11/12 ml-12'>
          <Suspense fallback={<LoadingSpinner header="Please wait" />}>
            <Switch>
              {routes.map((props, index) => <Route key={index} {...props} />)}
            </Switch>
          </Suspense>
        </div>
      </div>
      {serverError && <Toast onTimeOut={() => setServerError(null)} text={serverError} type="danger" />}
    </>
  );
};

export default App;
