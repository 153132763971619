export const PERMISSIONS = {
  IMPORT_DATA: 'import_data',
  VIEW_USER: 'view_user',
  VIEW_USER_LIST: 'view_user_list',
  INVITE_USER: 'invite_user',
  EDIT_USER: 'edit_user',
  DELETE_USER: 'delete_user',
  VIEW_ROLE: 'view_role',
  VIEW_ROLE_LIST: 'view_role_list',
  CREATE_ROLE: 'create_role',
  EDIT_ROLE: 'edit_role',
  DELETE_ROLE: 'delete_role',
  VIEW_LOG_LIST: 'view_log_list',
  CREATE_TRANSLATION: 'create_translation',
  VIEW_TRANSLATION_LIST: 'view_translations_list',
  EDIT_TRANSLATION: 'edit_translation',
  DELETE_TRANSLATION: 'delete_translation',
  VIEW_NOMENCLATORS_LIST: 'view_nomenclators_list',
  VIEW_FINANCIALS_LIST: 'view_financials_list',

  // Templates
  VIEW_TEMPLATES_LIST: 'view_templates_list'
};

export const FULL_PERMISSIONS = Object.values(PERMISSIONS);
